<template>
  <v-container>
    <v-row class="d-flex align-center justify-center">
      <v-col class="d-flex justify-center">
        <div class="text-center align-center justify-center">
          <p class="text-align-center display-1 font-weight-thin">
            Ajouter un corpus de transcription
          </p>
          <p class="subtitle">Un entretien sera créé pour chacun des fichiers sélectionné</p>
          <v-form ref="form" v-on:submit.prevent="upload()">
            <FormAlert :info="{alert}"></FormAlert>
            <v-row class="d-flex justify-center">
              <v-col class="d-flex">
                <v-progress-circular v-if="loading" :size="50"
                color="primary" indeterminate></v-progress-circular>
                <v-file-input v-else v-model="file" how-size label="Fichier" @change="onFileChange"
                 multiple>
                </v-file-input>
                <v-radio-group dense class="ml-5" v-model="language" mandatory row>
                  <v-radio label="Français" value="fr"></v-radio>
                  <v-radio label="Anglais" value="en"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn @click="previous()" color="red" class="mr-4" text dark>Annuler</v-btn>
              <v-btn :disabled="!file" :dark="!!file"
              type="submit" color="blue" class="mr-4" outlined>
              Envoyer
            </v-btn>
          </div>
        </v-form>
        <v-row v-show="showErrors">
          <v-col class="text-left">
            <p class="title">
              <v-icon color="orange">mdi-alert</v-icon> Erreurs (fichiers non traités)</p>
            <p v-html="errors"></p>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import FormAlert from './Alert.vue';

export default {
  name: 'UploadForm',
  mixins: [MixinInterviews],
  components: { FormAlert },
  props: {
    patientId: String,
  },
  data: () => ({
    loading: false,
    file: null,
    files: [],
    formData: null,
    language: 'fr',
    response: null,
    showErrors: false,
    errors: '',
  }),
  methods: {
    onFileChange(e) {
      this.files.push(...e);
    },

    previous() {
      this.$router.go(-1);
    },

    async upload() {
      this.loading = true;

      this.response = await this.uploadCorpus(
        this.patientId,
        this.files,
        'text',
        this.language,
      );

      this.loading = false;

      if (this.response.some((x) => !x.ok)) {
        this.showErrors = true;
        const files = this.response.filter((x) => !(x.ok));
        this.errors = files.map((x) => `<b>${x.file}</b>:<br/><pre>${x.msg}</pre>\n`).join();
      } else {
        this.previous();
      }
    },
  },
};
</script>
